//
//
//  Pricing
//
//

import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"
import {MainLayout} from "../../ui/layout/MainLayout.tsx";
import {Loading} from "@carbon/react";
import {useTranslation} from "react-i18next";
import { useGetTokenOrganization } from "../../hooks/useGetTokenOrganization.tsx";



const LoadStripeScript = () => {
    // We need to load the Stripe script in order to use the Stripe web component
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://js.stripe.com/v3/pricing-table.js"
        script.async = true
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return null
}


function Pricing() {
    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useLogto()
    const [stripeSubscription, setStripeSubscription] = useState<object | undefined>(undefined)
    const [stripeCustomerSecret, setStripeCustomerSecret] = useState<string | undefined>(undefined)
    const [stripeClientReferenceId, setStripeClientReferenceId] = useState<string | undefined>(undefined)
    const [pricingTableId, setPricingTableId] = useState<string | undefined>(undefined)
    const {t, i18n} = useTranslation()
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const redirectToPortal = async () => {
        const token = await fetchUpdatedToken();
        const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/subscription/portal`, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` }
        });

        const data = await response.json();
     
        window.location.href = data.url;
        
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await fetchUpdatedToken();
                const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/subscription`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` }
                });

                const data = await response.json();
                
                if (!data.subscription) {
                    const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/pricing`, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    const data = await response.json();
                    setStripeCustomerSecret(data.session_client_secret);
                    setStripeClientReferenceId(data.client_reference_id);
                } else {
                    setStripeSubscription(data.subscription);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData().then();
    }, [fetchUpdatedToken, getIdTokenClaims]);


    useEffect(() => {
        // change pricing table id based on language
        if (i18n.language === 'es') {
            setPricingTableId(import.meta.env.VITE_STRIPE_PRICING_TABLE_ES_ID)
        } else {
            setPricingTableId(import.meta.env.VITE_STRIPE_PRICING_TABLE_ES_ID)
        }

    }, [i18n.language])

    useEffect(() => {
        if (stripeSubscription) {
            redirectToPortal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeSubscription]);

    if (isLoading || stripeSubscription) {
        return (
            <MainLayout>
                <h2 style={{marginBottom: '2rem'}}>{t('menu.subscription')}</h2>
                <Loading style={{margin: '0 auto'}} className="pricing-loading" withOverlay={false}/>
            </MainLayout>
        )
    }
   
    

    return (
        <>
            <MainLayout>
                <h2 style={{marginBottom: '2rem'}}>{t('menu.subscription')}</h2>

            
                <stripe-pricing-table
                    pricing-table-id={pricingTableId}
                    publishable-key={import.meta.env.VITE_STRIPE_PRICING_TABLE_KEY}
                    client-reference-id={stripeClientReferenceId}
                    customer-session-client-secret={stripeCustomerSecret}
                ></stripe-pricing-table>
              

                <LoadStripeScript/>
            </MainLayout>
        </>
    )
}

export default Pricing
